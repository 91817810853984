<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="!!data">
    <InputRadio
        ref="input"
        v-model="data"
        :options="shareOptions"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        class="radio-options-wide"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../helpers/questionLoadHelpers";
import {vaultOptions} from "../../options/vaultOptions";

import QuestionBase from "../../QuestionBase";
import InputRadio from "../../inputs/InputRadio";

export default {
  name: 'Share',
  mixins: [questionLoadHelpers, vaultOptions],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
  },
  mounted () {
    this.setOptions()
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'Would you want vault items shared?',
        subTitle: null,
        tip: null,
      },
      db: {
        saveLocation: 'vault_data_save',
        saveField: 'share',
        formPath: 'data.settings.share',
        jsonSaveField: 'share'
      },
      args: {
        skippable: false,
        label: null,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
      options: []
    }
  },
  methods: {
    setOptions () {
      this.options = [
        {
            label: 'Do not share',
            value: 'Do not share'
        },
        {
            label: 'On Death',
            value: 'On Death'
        },
        {
            label: 'On Incapacitation',
            value: 'On Incapacitation'
        },
        {
            label: 'On Death or Incapacitation',
            value: 'On Death or Incapacitation'
        },
      ]
    }
  }
}
</script>
