<template>
  <QuestionnaireBase :title="title" :form="form" :finishFunction="finishFunction">
    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "../loadHelpers";
import {dateFormat} from "@/mixins/dateFormat";

import QuestionnaireBase from "../../../components/common/questionnaires/questionnaire";

import VaultSettings from "@/components/common/questionnaires/fieldsets/vault/VaultSettings";
import VaultSettingsOverwrite from "@/components/common/questionnaires/fieldsets/vault/VaultSettingsOverwrite";

import {httpQuestionnaire} from "@/services";


export default {
  name: "VaultFull",
  mixins: [loadHelpers, dateFormat],
  components: {
    QuestionnaireBase,
    VaultSettings,
    VaultSettingsOverwrite,
  },
  data() {
    return {
      name: 'VaultFull',
      title: "Vault Settings",
      url: 'vault',
      order:
          ['VaultSettings',
          'VaultSettingsOverwrite'
          ],
      finishFunction: this.instructionsTaken,
      expiryAccessCodeOnFinish: true
    }
  },
  methods: {
    async instructionsTaken() {
      return httpQuestionnaire
          .patch(this.url, {
            vault_id: this.form.id
          })
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
    }
  },


};
</script>
