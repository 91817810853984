<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Vault Settings</h3>

    <OverwriteExistingYN
        v-model="form.data.settings.update_existing_items"
    />

  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";

import OverwriteExistingYN from "../../question/questions/vault/OverwriteExistingYN";

export default {
  name: 'VaultSettingsOverwrite',
  mixins: [
    fieldsetHelpers,
  ],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    OverwriteExistingYN,
  },
  data() {
    return {
      label: 'Overwrite', // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
  }
};
</script>
