<template>
  <QuestionBase
    :id="db.saveField"
    :errors="[...jsErrors]"
    :question="question"

    :valid="!!data.length"
  >
    <!-- Selected People -->
    <div class="accordion sub-items">
      <transition-group name="fade">
        <ObjectCard
          v-for="(person, index) in data"
          :id="'sharedPeopleData' + index"
          :key="'sharedPeopleData' + person.id"
          :ref="'sharedPeopleData' + index"
          :select-mode="true"
          :index="index"
          :selected="true"
          :loading="false"
          :title="cardTitle(person)"
          :value="data[index]"
          :show-delete="false"
          type="sharedPeopleData"
          @click="
            deselectConfirm(
                  person,
                  'Remove person from your list?'
                )
          "
          @delete="deletePerson(person.id)"
          @save="
            savePerson(
              null,
              'sharedPeopleData' + index
            )
          "
        >
          <PersonVault
            v-if="person.type === 'person'"
            v-model="data[index]"
            :key="'sharedPeople-form-data' + person.id"
            :errors-post="errorsPatch"
            :no-save="true"
            :objectId="person.id"
            @save="savePersonForm($event, person)"
          />

        </ObjectCard>
      </transition-group>
    </div>

    <!-- New People -->
    <transition name="fade">
      <template>
        <b-button
          class="btn-question w-100"
          @click="show.addPerson = true"
        >
          <i class="i-Add text-25 font-weight-800 mr-2"> </i> Add Person
        </b-button>
      </template>
    </transition>

    <VaultPeopleModal
      v-model="show.addPerson"
      :options="optionsData"
      :dataSelected="data"
      title="Add Person"
      :sub-title="'Select existing people or add new people. You can select multiple individuals.'"
      :hide-type-options="true"
      @dataSelectedUpdate="data = $event"
      @save="save"
    />
  </QuestionBase>
</template>

<script>
import { personHelpers } from "@/mixins/personHelpers";
import { peopleSaveHelpers} from "@/components/common/questionnaires/question/questions/objects/peopleSaveHelpers";
import { questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";
import { saveHelpers } from "@/views/questionnaires/saveHelpers";

import { vaultPeopleHelpers } from "@/components/common/questionnaires/question/questions/vault/helpers/vaultPeopleHelpers";
import { vaultPartnerHelpers} from "@/components/common/questionnaires/fieldsets/vault/helpers/vaultPartnerHelpers";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import ObjectCard from "@/components/common/questionnaires/question/questions/objects/ObjectCard";

import PersonVault from "@/components/common/questionnaires/fieldsets/sub/people/PersonVault";
import VaultPeopleModal from "@/components/common/questionnaires/question/questions/vault/helpers/VaultPeopleModal";

export default {
  name: "People",
  components: {
    VaultPeopleModal,
    PersonVault,
    ObjectCard,
    QuestionBase,
  },
  mixins: [
    peopleSaveHelpers,
    personHelpers,
    questionLoadHelpers,
    saveHelpers,
    vaultPeopleHelpers,
    vaultPartnerHelpers,
  ],
  props: {
    value: {
      type: Array,
      required: false,
    },
    settings: {
      type: Object,
      required: true,
    },
    optionsData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      db: {
        saveLocation: "vault_data_save",
        saveField: "people",
        formPath: "data.settings.people",
        jsonSaveField: "people",
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      errorsPatch: {},
      jsErrors: [],
      skipped: false,
      show: {
        addPerson: false,
      },
    };
  },
  computed: {
    question() {
      return {
        title: "Who would you like to share with?",
        subTitle:
          "Please confirm who you would like to share vault items. To deselect a person, simply click on their name." ,
        tip: null,
      };
    },
    data: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
  },
};
</script>
